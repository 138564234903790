import CardCustom from '~/components/Molecules/Organic/CardCustom/CardCustom.vue'
import device from '~/mixins/device.js'

export default {
  mixins: [device],

  components: {
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      required: true,
    },

    cta: {
      type: Object,
      required: true,
    },

    list: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      cardCustom: CardCustom,
      swiperOptions: {
        spaceBetween: 66,
        allowTouchMove: true,
        normalizeSlideIndex: false,
        centeredSlides: false,
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 48,
          },
          768: {
            slidesPerView: 2.2,
            spaceBetween: 16,
          },
          320: {
            slidesPerView: 1.1,
            spaceBetween: 0,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          el: '.swiper-navigation',
          clickable: true,
        },
      },
    }
  },
}
